<template>
  <div class="py-4">
    <form @submit.prevent="submitForm" @reset.prevent="clearForm">
      <div class="flex justify-center">
        <div class="w-full max-w-2xl flex flex-col justify-between">
          <label for="from" class="pb-1 font-semibold">Sending From</label>
          <input
            disabled
            name="from"
            v-model="sendText.fromNumber"
            type="text"
            class="w-full mb-5 p-3 rounded border-gray-300"
          />
          <label for="phone" class="pb-1 font-semibold">Phone</label>
          <input
            name="phone"
            ref="phone"
            v-model="sendText.toNumber"
            type="text"
            class="w-full mb-5 p-3 rounded border-gray-300"
            @change="validatePhone"
          />
          <label for="message" class="pb-1 font-semibold">Message</label>
          <textarea
            name="message"
            v-model="sendText.message"
            rows="10"
            class="w-full mb-5 p-3 rounded border-gray-300"
          ></textarea>

          <div class="border border-gray-300 rounded bg-white p-4 mb-6">
            <input
              class="block"
              ref="image"
              type="file"
              accept="image/*"
              @change="previewImage"
            />
            <div
              v-if="imageData || img"
              class="mt-4 bg-gray-600 w-max relative"
            >
              <button
                class="absolute -top-3 -right-3 rounded-full"
                @click="removeImage()"
              >
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
              <img class="max-w-xl w-full h-auto" :src="img" />
            </div>
          </div>

          <div
            role="alert"
            class="border border-red-400 rounded bg-red-100 text-red-700 p-4 mb-6"
            v-if="errorMsg.length"
          >
            <p>{{ errorMsg }}</p>
          </div>

          <div class="w-full max-w-xs mx-auto flex flex-row justify-between">
            <button
              type="reset"
              class="px-6 py-2 bg-gray-700 hover:bg-gray-600 text-white font-bold"
            >
              Reset
            </button>
            <button
              type="submit"
              class="px-6 py-2 bg-gray-700 hover:bg-gray-600 text-white font-bold"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
const firebase = require("../firebaseConfig");
import axios from "axios";

export default {
  name: "MessagingSendTextComponent",
  data: () => ({
    errorMsg: "",
    sendText: {
      fromNumber: "",
      toNumber: "",
      message: "",
      image: "",
    },
    user: {},
    location: {},
    business: {},
    imageData: null,
    imageName: "",
    img: null,
  }),
  async mounted() {
    this.user = await this.getUserInfo();
    this.location = await this.getLocationInfo();
    this.business = await this.getBusinessInfo();
    this.sendText.fromNumber = this.location.twilio_number;
  },
  methods: {
    getUserInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("users")
          .doc(firebase.auth.currentUser.uid)
          .get()
          .then((user) => {
            resolve({ id: user.id, ...user.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLocationInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("businesses")
          .doc(this.user.business_id)
          .collection("locations")
          .doc(this.user.location_id)
          .get()
          .then((location) => {
            resolve({ id: location.id, ...location.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBusinessInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("businesses")
          .doc(this.user.business_id)
          .get()
          .then((business) => {
            resolve({ id: business.id, ...business.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    validatePhone() {
      this.errorMsg = "";
      this.sendText.toNumber = this.sendText.toNumber
        .replace(/[^0-9]/g, "")
        .replace(/^1+/g, "");
      if (this.sendText.toNumber.length != 10) {
        this.errorMsg = `Phone number has to be exactly 10 digit`;
        this.$refs.phone.focus();
        return false;
      }
      return true;
    },
    clearForm() {
      this.sendText.toNumber = "";
      this.sendText.message = "";
      this.removeImage();
    },
    removeImage() {
      this.$refs.image.value = null;
      this.imageData = null;
      this.img = null;
    },
    previewImage(event) {
      this.imageData = event.target.files[0];
      console.info("image data is ", this.imageData);
      this.img = URL.createObjectURL(this.imageData);
      this.imageName = `${this.imageData.lastModified}${this.imageData.name}`;
    },
    async submitForm() {
      const business_id = this.business.id;
      const location_id = this.location.id;
      const to_number = this.sendText.toNumber;
      const from_number = this.sendText.fromNumber;
      const text_message = this.sendText.message;
      const image_name = this.imageName;
      const image_data = this.imageData;
      const display_name = this.user.display_name;

      let conversation;
      let download_url;
      let last_message;
      let is_conversation = false;

      if (image_data) {
        const storageRef = firebase.storage.ref(
          `${business_id}/send-text/${image_name}`
        );
        await storageRef.put(image_data);

        download_url = await firebase.storage
          .ref(business_id)
          .child("send-text")
          .child(image_name)
          .getDownloadURL();

        last_message = "Image";
      }

      this.clearForm();

      // record message sent in business
      await firebase.db
        .collection("businesses")
        .doc(business_id)
        .update({
          messages_sent: firebase.firestore.FieldValue.increment(1),
        });

      // record message sent in the location
      await firebase.db
        .collection("businesses")
        .doc(business_id)
        .collection("locations")
        .doc(location_id)
        .update({
          location_messages_sent: firebase.firestore.FieldValue.increment(1),
        });

      // check customer collection
      await firebase.db
        .collection("businesses")
        .doc(business_id)
        .collection("customers")
        .where("customer_number", "==", to_number)
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            // add new customer
            firebase.db
              .collection("businesses")
              .doc(business_id)
              .collection("customers")
              .add({
                customer_label: to_number,
                customer_number: to_number,
              });
          }
        });

      // check if this is a existing conversation
      await firebase.db
        .collection("businesses")
        .doc(business_id)
        .collection("locations")
        .doc(location_id)
        .collection("conversations")
        .where("customer_number", "==", to_number)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            conversation = {
              id: snapshot.docs[0].id,
              ...snapshot.docs[0].data(),
            };
            // set flag for message collection
            is_conversation = true;
          }
        });

      if (is_conversation) {
        if (text_message.length > 0) {
          last_message = text_message;
        }
        if (text_message.length > 60) {
          last_message = text_message.substring(0, 60) + "...";
        }
        // update conversation collection
        await firebase.db
          .collection("businesses")
          .doc(business_id)
          .collection("locations")
          .doc(location_id)
          .collection("conversations")
          .doc(conversation.id)
          .update({
            last_message_timestamp: new Date(),
            last_message: last_message,
          });
      }

      const authToken = await firebase.auth.currentUser.getIdToken();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_FIREBASE_FUNCTIONS_URL}/sendTwilio`,
        headers: {
          Authorization: authToken,
        },
        data: {
          to: to_number,
          from: from_number,
          content: text_message,
          media: download_url,
          is_conversation: is_conversation,
          display_name: display_name,
          business_id: business_id,
          location_id: location_id,
          is_mass_text: false,
          is_review_text: false,
          is_send_text: true,
        },
      });
    },
  },
};
</script>
