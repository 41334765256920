<template>
  <MessagingSendTextComponent />
</template>

<script>
import MessagingSendTextComponent from '../components/MessagingSendTextComponent.vue'

export default {
  name: 'MessagingSendText',
  components: {
    MessagingSendTextComponent,
  },
}
</script>
